import * as React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "react-intl"
import { changeRouteLang, getRouteLang } from "../utils/functions"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

import logoSmall from "../assets/images/layout/logo_small.png"

function Header({ route }) {
  const lang = getRouteLang(route)
  return (
    <header>
      {/* Navigation */}
      <nav
        id="header"
        className="fixed w-full z-30 top-0 text-white bg-white shadow"
      >
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
          <div className="pl-4 flex items-center">
            <Link
              className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
              to="/"
            >
              <img
                src={logoSmall}
                className=" fill-current inline"
                alt="compass project logo small"
              />
            </Link>
          </div>

          {
            /* <div className="block lg:hidden pr-4"> */
            // Replace the line below with line above when button actually show the menu for small screens...
          }
          <div className="hidden pr-4">
            <button
              id="nav-toggle"
              className="w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 bg-white"
            >
              <svg
                className="fill-current h-3 w-3"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>

          <div
            className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
            id="nav-content"
          >
            <ul className="list-reset lg:flex justify-end flex-1  items-end ">
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#the-project`}
                >
                  <FormattedMessage id="The project" />
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#timeline`}
                >
                  <FormattedMessage id="TIMELINE" />
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#tool-bank`}
                >
                  <FormattedMessage id="TOOL BANK" />
                </Link>
              </li>
              <li className="lowercase mr-3">
                <Link
                  className="capitalize inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#blended-course`}
                >
                  <FormattedMessage
                    className="capitalize"
                    id="BLENDED COURSE"
                  />
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#digital-book`}
                >
                  <FormattedMessage id="DIGITAL BOOK" />
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#geocaching-cultural-route`}
                >
                  <FormattedMessage id="GEOCACHING CULTURAL ROUTE" />
                </Link>
              </li>
              <li className="mr-3">
                <Link
                  className="inline-block text-black no-underline hover:text-gray-800 hover:underline py-2 pl-4"
                  to={`/${lang}#partners`}
                >
                  <FormattedMessage id="OUR PARTNERS" />
                </Link>
              </li>
              <li>
                <div className="dropdown inline-block relative">
                  <button className="py-2 px-4 rounded inline-flex items-center hover:underline ">
                    <span className="mr-1">
                      <FormattedMessage id="Languages" />
                    </span>
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </button>
                  <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
                    <li className="">
                      <Link
                        className="rounded-t bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("en", route)}
                        activeClassName="active-lang-link"
                      >
                        English
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("de", route)}
                        activeClassName="active-lang-link"
                      >
                        German
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("es", route)}
                        activeClassName="active-lang-link"
                      >
                        Spanish
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("hr", route)}
                        activeClassName="active-lang-link"
                      >
                        Croatian
                      </Link>
                    </li>

                    <li className="">
                      <Link
                        className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("pt", route)}
                        activeClassName="active-lang-link"
                      >
                        Portuguese
                      </Link>
                    </li>

                    <li className="">
                      <Link
                        className="bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("it", route)}
                        activeClassName="active-lang-link"
                      >
                        Italian
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        className="rounded-b bg-gray-200 hover:bg-gray-400 py-2 px-4 block whitespace-no-wrap"
                        to={changeRouteLang("lv", route)}
                        activeClassName="active-lang-link"
                      >
                        Latvian
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <a
              href="https://www.facebook.com/compassprojecteu"
              className=" ml-5 hover:bg-gray-300 text-blue-600 "
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
          </div>
        </div>

        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
      </nav>
    </header>
  )
}

export default Header
