import * as React from "react"
import { FormattedMessage } from "react-intl"
import euCoFounded from "../assets/images/layout/eu-cofounded.jpg"
import creativeCommons from "../assets/images/layout/creative-commons.png"

function Footer(params) {
  return (
    <footer className="bg-white">
      <div className="container mx-auto  px-8">
        <div className="flex justify-content-evenly items-center py-6 text-gray-500">
          <img
            className="w-1/6"
            src={creativeCommons}
            alt="Part of creative commons network (monetization is forbidden ) "
          />
          <img
            className="w-1/5"
            src={euCoFounded}
            alt="EU flag with inner text saying 'co-founded by the EU' "
          />

          <q className="text-center ml-3">
            <FormattedMessage id="disclaimer1" />
          </q>
        </div>
        <div className=" justify-content-evenly items-center  text-gray-500 "><FormattedMessage id="disclaimer2" /></div>
      </div>

      <a href="https://www.freepik.com/free-photos-vectors/background">
        Background vector created by freepik - www.freepik.com
      </a>
    </footer>
  )
}

export default Footer
