function changeRouteLang(lang, route) {
  let routeSplitted
  routeSplitted = route.split("/")
  routeSplitted[1] = lang
  return routeSplitted.join("/")
}

function getRouteLang(route) {
  let routeSplitted
  routeSplitted = route.split("/")

  return routeSplitted[1]
}

export { changeRouteLang, getRouteLang }
